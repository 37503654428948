<template>
  <v-container class="discounts">
    <ebsn-meta
      class="description"
      :target="category"
      path="category_info.DESCRIPTION"
      tag="div"
    ></ebsn-meta>
    <!--
    <h4 class="text-uppercase default--text">
      {{ $t("coupons.discounts.usePoints") }}
    </h4>
    
    <h4 class="default--text font-weight-regular">
      {{ $t("coupons.discounts.yourPoints") }}
      <strong>{{ points }}</strong>
    </h4>
        -->
    <PointsProgressBar
      v-if="points != null"
      :points="points"
      :segments="segments"
    />
    <v-tabs
      grow
      color="white"
      background-color="primary lighten-1"
      class="segment-style-tabs"
      v-model="tab"
    >
      <v-tab hidden="true" :ripple="false" tab-value="discounts"
        ><span>{{ $t("coupons.discounts.discounts") }}</span></v-tab
      >
      <v-tab :ripple="false" tab-value="donations"
        ><span>{{ $t("coupons.discounts.donations") }}</span></v-tab
      >
      <v-tab-item class="pa-1" value="discounts">
        <v-row>
          <!--
          <v-col cols="12"
            ><div class="text-center text-body-0 mt-2">
              {{ $t("coupons.discounts.viewMore") }}
            </div></v-col
          >
          -->
          <v-col
            cols="12"
            sm="6"
            v-for="(discount, index) in activeWelcomeDiscountCoupons"
            :key="'active-discount-' + index"
            class="coupon-col"
          >
            <CouponCard
              :coupon="discount"
              @showDetails="showActiveCoupon"
              @reload="reload"
              :type="'welcomeCoupon'"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-for="(discount, index) in activeDiscountCoupons"
            :key="'active-discount-' + index"
            class="coupon-col"
          >
            <CouponCard
              :coupon="discount"
              @showDetails="showActiveCoupon"
              @reload="reload"
              :type="'activeDiscount'"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-for="(discount, index) in discountCoupons"
            :key="'discount-' + index"
            class="coupon-col"
          >
            <CouponCard
              :coupon="discount"
              @showDetails="showDetails"
              @reload="reload"
              :type="'discount'"
              :userPoints="points"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-for="(discount, index) in vouchers"
            :key="'voucher-' + index"
          >
            <CouponCard
              :coupon="discount"
              @showDetails="showVoucher"
              @reload="reload"
              :type="'voucher'"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="pa-1" value="donations">
        <v-row>
          <!--
          <v-col cols="12" class="text-body-0 mt-2"
            ><div class="font-weight-semibold">
              {{ $t("coupons.discounts.solidarity") }}
            </div>
            <div>{{ $t("coupons.discounts.solidarityInfo") }}</div>
            <div class="mt-2">
              {{ $t("coupons.discounts.viewMore") }}
            </div></v-col
          >
          <v-col
            cols="12"
            sm="6"
            v-for="(donation, index) in donationCoupons"
            :key="'donation-' + index"
            class="coupon-col"
          >
            <CouponCard
              :coupon="donation"
              @showDetails="showDetails"
              @reload="reload"
              :type="'donation'"
              :userPoints="points"
            />
          </v-col>
        -->
        </v-row>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<style lang="scss">
.discounts {
  padding: 10px 10px;
}
</style>
<script>
import PointsProgressBar from "@/components/coupon/PointsProgressBar.vue";
import CopuonService from "@/service/couponsService";
import CouponCard from "@/components/coupon/CouponCard.vue";
import CouponDetail from "@/components/coupon/CouponDetail.vue";
import ActiveCouponDetail from "@/components/coupon/ActiveCouponDetail.vue";
import VoucherDetail from "@/components/coupon/VoucherDetail.vue";
import categoryMixins from "~/mixins/category";
export default {
  components: { PointsProgressBar, CouponCard },
  name: "Discounts",
  mixins: [categoryMixins],
  data() {
    return {
      segments: [],
      points: null,
      discountCoupons: [],
      donationCoupons: [],
      activeDiscountCoupons: [],
      activeWelcomeDiscountCoupons: [],
      vouchers: [],
      loading: false,
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 0
      },
      tab: "discounts"
    };
  },
  computed: {},
  methods: {
    async reload() {
      await this.getProgressBar();
      await this.loadActiveWelcomeDiscounts();
      await this.loadActiveDiscounts();
      await this.loadDiscounts();
      await this.loadDonations();
      await this.loadVouchers();
    },
    async getProgressBar() {
      CopuonService.getProgressBar().then(data => {
        this.segments = data.data.value.coupons;
        this.points = data.data?.value?.userPoints;
      });
    },
    async loadDiscounts() {
      CopuonService.getCouponsByCategoryId(34661).then(data => {
        this.discountCoupons = data.products;
      });
    },
    async loadActiveDiscounts() {
      CopuonService.getActiveCopuns().then(data => {
        this.activeDiscountCoupons = data.data.values;
      });
    },
    async loadDonations() {
      CopuonService.getCouponsByCategoryId(34662).then(data => {
        this.donationCoupons = data.products;
      });
    },
    async loadVouchers() {
      CopuonService.getVouchers().then(data => {
        this.vouchers = data.giftCertificates;
      });
    },
    async loadActiveWelcomeDiscounts() {
      CopuonService.getActivatedDiscountsByType("welcome").then(data => {
        this.activeWelcomeDiscountCoupons = data.values;
      });
    },
    async showDetails(coupon, type) {
      let res = await this.$dialog.show(CouponDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        couponSlug: coupon.slug,
        userPoints: this.points,
        type: type,
        width: 500
      });
      if (res) {
        console.log(res);
      }
    },
    async showActiveCoupon(coupon, type) {
      let res = await this.$dialog.show(ActiveCouponDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        coupon: coupon,
        type: type,
        width: 500
      });
      if (res) {
        console.log(res);
      }
    },
    async showVoucher(voucher) {
      let res = await this.$dialog.show(VoucherDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        coupon: voucher,
        width: 500
      });
      if (res) {
        console.log(res);
      }
    }
  },
  async created() {
    await this.reload();
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
  }
};
</script>
