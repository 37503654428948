var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "discounts" },
    [
      _c("ebsn-meta", {
        staticClass: "description",
        attrs: {
          target: _vm.category,
          path: "category_info.DESCRIPTION",
          tag: "div"
        }
      }),
      _vm.points != null
        ? _c("PointsProgressBar", {
            attrs: { points: _vm.points, segments: _vm.segments }
          })
        : _vm._e(),
      _c(
        "v-tabs",
        {
          staticClass: "segment-style-tabs",
          attrs: {
            grow: "",
            color: "white",
            "background-color": "primary lighten-1"
          },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab",
            {
              attrs: { hidden: "true", ripple: false, "tab-value": "discounts" }
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("coupons.discounts.discounts")))
              ])
            ]
          ),
          _c("v-tab", { attrs: { ripple: false, "tab-value": "donations" } }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("coupons.discounts.donations")))])
          ]),
          _c(
            "v-tab-item",
            { staticClass: "pa-1", attrs: { value: "discounts" } },
            [
              _c(
                "v-row",
                [
                  _vm._l(_vm.activeWelcomeDiscountCoupons, function(
                    discount,
                    index
                  ) {
                    return _c(
                      "v-col",
                      {
                        key: "active-discount-" + index,
                        staticClass: "coupon-col",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _c("CouponCard", {
                          attrs: { coupon: discount, type: "welcomeCoupon" },
                          on: {
                            showDetails: _vm.showActiveCoupon,
                            reload: _vm.reload
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.activeDiscountCoupons, function(discount, index) {
                    return _c(
                      "v-col",
                      {
                        key: "active-discount-" + index,
                        staticClass: "coupon-col",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _c("CouponCard", {
                          attrs: { coupon: discount, type: "activeDiscount" },
                          on: {
                            showDetails: _vm.showActiveCoupon,
                            reload: _vm.reload
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.discountCoupons, function(discount, index) {
                    return _c(
                      "v-col",
                      {
                        key: "discount-" + index,
                        staticClass: "coupon-col",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _c("CouponCard", {
                          attrs: {
                            coupon: discount,
                            type: "discount",
                            userPoints: _vm.points
                          },
                          on: {
                            showDetails: _vm.showDetails,
                            reload: _vm.reload
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.vouchers, function(discount, index) {
                    return _c(
                      "v-col",
                      {
                        key: "voucher-" + index,
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _c("CouponCard", {
                          attrs: { coupon: discount, type: "voucher" },
                          on: {
                            showDetails: _vm.showVoucher,
                            reload: _vm.reload
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            { staticClass: "pa-1", attrs: { value: "donations" } },
            [_c("v-row")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }